// Imports => React
import React, { useEffect, useState, useMemo, memo } from 'react';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import clsx from 'clsx';

// Imports => config
import config from '@config';

// Imports => Constants
import { KEYS, THEMES, TYPES, VARIANTS, VISUALS } from '@constants';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcButton from '@atoms/ac-button/ac-button.web';

const _CLASSES = {
	MAIN: 'ac-timeout-notice-modal',
	DENSE: 'ac-timeout-notice-modal--dense',
	CONTENT: 'ac-timeout-notice-modal__content',
};

let ttTimer = null;

const AcTimeoutNoticeModal = ({ callback, update, checkActivity }) => {
	const [remaining, setRemaining] = useState(60);
	const [ttUnAuth, setTTUnAuth] = useState(false);

	const endSession = () => {
		clearTimeout(ttTimer);
		setTTUnAuth(true);
		window.FaviconNotification.remove();
		if (update) update();
	};

	const handleEndSession = () => {
		endSession();
		const unauthenticatedEvent = new CustomEvent('unAuthenticate');
		window.dispatchEvent(unauthenticatedEvent);
	};

	const handleCancel = (event) => {
		if (event && event.preventDefault) event.preventDefault();
		clearTimeout(ttTimer);
		setTTUnAuth(false);

		if (callback) callback();
	};

	const handleReload = (event) => {
		if (event && event.preventDefault) event.preventDefault();
		clearTimeout(ttTimer);
		setTTUnAuth(false);

		if (window && window.location) window.location.reload();
	};

	useEffect(() => {
		if (!ttUnAuth) {
			ttTimer = setTimeout(() => {
				if (checkActivity && checkActivity()) handleCancel()
				else if (remaining <= 0) {
					clearTimeout(ttTimer);
					handleEndSession();
				} else {
					setRemaining(remaining - 1);
				}
			}, 1000);
		}
	}, [ttUnAuth, remaining]);

	const renderInactiveMessage = useMemo(() => {
		const unit = remaining === 1 ? 'second' : 'seconds';
		let message = `<p>You have been inactive for a while.</p><p class="h-margin-y-15">For your security, we will automatically log you out in <strong>${remaining} ${unit}</strong>.</p>`;
		message += `<p>Do you want to stay logged in and keep going?</p>`;

		return <AcRichContent content={message} />;
	}, [remaining, ttUnAuth]);

	const renderSignedOutMessage = useMemo(() => {
		let message = `<p>Due to browser inactivity you have been logged out and your session has ended.</p>`;
		message += `<p class="h-margin-top-15">Log in again if you wish to get back in.</p>`;

		return <AcRichContent content={message} />;
	}, [ttUnAuth]);

	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN, ttUnAuth && _CLASSES.DENSE]);
	}, [ttUnAuth]);

	const getSubmitButtonOptions = useMemo(() => {
		const theme = ttUnAuth ? THEMES.ALPHA : THEMES.OMEGA;
		const title = ttUnAuth ? 'Log in' : 'Keep me logged in';
		const handleCallback = ttUnAuth ? handleReload : handleCancel;

		return {
			type: TYPES.BUTTON,
			theme,
			title,
			callback: handleCallback,
		};
	}, [ttUnAuth, handleCancel, handleReload]);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				<ReactCSSTransitionReplace
					transitionName="fade-wait"
					transitionEnterTimeout={500}
					transitionLeaveTimeout={500}
				>
					<div key={ttUnAuth}>
						{!ttUnAuth && (
							<AcContainer fluid>
								<AcRow>
									<AcColumn className={'h-text--align-center h-margin-top-10'}>
										{renderInactiveMessage}
									</AcColumn>
								</AcRow>

								<AcRow className={'h-margin-top-40'}>
									<AcColumn className={'h-text--align-center'}>
										<AcButton {...getSubmitButtonOptions}>
											<span>Keep me logged in</span>
										</AcButton>
									</AcColumn>
								</AcRow>
							</AcContainer>
						)}
						{ttUnAuth && (
							<AcContainer fluid>
								<AcRow>
									<AcColumn className={'h-text--align-center h-margin-top-20'}>
										{renderSignedOutMessage}
									</AcColumn>
								</AcRow>

								<AcRow className={'h-margin-top-40'}>
									<AcColumn className={'h-text--align-center'}>
										<AcButton {...getSubmitButtonOptions}>
											<span>Log in</span>
										</AcButton>
									</AcColumn>
								</AcRow>
							</AcContainer>
						)}
					</div>
				</ReactCSSTransitionReplace>
			</div>
		</div>
	);
};

export default memo(AcTimeoutNoticeModal);
