import React, { useEffect, useCallback, useRef } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { KEYS } from '@constants';
import AcLoader from '@atoms/ac-loader/ac-loader.web';
import { AcCompanySearch } from '@components/ac-company-search';

const AcSelectErpCompany = ({ store: { erp } }) => {
  const {
    current_companies,
    current_company,
    current_company_query,
    is_loading,
  } = erp;

  const didMount = useRef(false);

  useEffect(() => {
    erp.resetCompanies();
    erp.resetCompany();
  }, []);

  useEffect(() => {
    if (didMount.current) {
      if (current_company_query) erp.listAllCompanies();
    } else {
      didMount.current = true;
      erp.resetCompanies();
    }
  }, [current_company_query]);

  const handleOnFilterChange = useCallback((filterValue) => {
    erp.setCompaniesQuery(filterValue);
    if (!current_company_query) {
      erp.resetCompany();
    }
    if (!filterValue) {
      erp.resetCompanies();
    }
    if (
      current_company_query &&
      filterValue !== undefined &&
      filterValue !== current_company_query
    ) {
      if (current_company) {
        erp.resetCompany();
      }
    }
  });

  const handleSelectCompany = useCallback(
    (item) => {
      if (current_companies && item) {
        erp.set(
          KEYS.COMPANY,
          current_companies.find((company) => company.erp_id === item.erp_id)
        );
      } else {
        erp.resetCompanies();
        erp.resetCompany();
      }
    },
    [current_companies]
  );

  return (
    <div className={'ac-wizard-content'}>
      <div className={'ac-wizard-step--header'}>
        <div className={'ac-wizard-step--fill-h'}>
          Select the company you want to import from ERP
        </div>
      </div>

      <AcCompanySearch
        value={current_company && current_company.name}
        onSelectItem={handleSelectCompany}
        results={current_companies}
        loading={is_loading}
        filter={handleOnFilterChange}
      />

      {is_loading && (
        <div className={'ac-wizard-loading'}>
          <AcLoader loading={true} />
        </div>
      )}
    </div>
  );
};

export default withStore(observer(AcSelectErpCompany));
