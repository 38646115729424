import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import clsx from 'clsx';

// Imports => atoms
import AcIcon from '@atoms/ac-icon/ac-icon.web';

export const AcSelectColumn = ({ header, data }) => {
  return (
    <>
      <AcColumn className={'h-margin-x-10'}>
        {header && (
          <div className={clsx('ac-select-row--header')}>{header}</div>
        )}
        <div className={clsx('ac-select-row--data')}>{data}</div>
      </AcColumn>
    </>
  );
};

export const AcSelectRow = ({
  selected,
  icon = null,
  disabled,
  item,
  onSelect,
  children,
  onHover = null,
}) => {
  const handleClickItem = useCallback(() => {
    onSelect({ item, selected: !selected });
  }, [selected]);

  const [hovered, setHovered] = useState();

  const handleMouseEvent = (bl) => () => {
    setHovered(bl);
  };

  return (
    <div
      className={clsx(
        'ac-select-row',
        selected && 'ac-select-row__selected',
        disabled ? 'ac-select-row__disabled' : ''
      )}
      onClick={() => handleClickItem()}
    >
      <AcContainer fluid>
        <AcRow>
          {(selected || disabled) && icon && (
            <AcColumn className={'ac-select-row--icon h-margin-left-10'}>
              <div
                {...(onHover && {
                  onMouseEnter: handleMouseEvent(true),
                  onMouseLeave: handleMouseEvent(false),
                })}
              >
                <AcIcon
                  icon={icon}
                  className={`ac-select-row--icon ${
                    disabled ? 'disabled' : ''
                  }`}
                />
              </div>
            </AcColumn>
          )}

          {(hovered && onHover && (
            <AcColumn className={clsx('h-margin-x-10')}>{onHover}</AcColumn>
          )) ||
            children}
        </AcRow>
      </AcContainer>
    </div>
  );
};
