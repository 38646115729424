// Imports => React
import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import {
	KEYS,
	PERMISSIONS,
	ROUTES,
	THEMES,
	TYPES,
	VARIANTS,
	VISUALS,
} from '@constants';

// Imports => Utilities
import { AcIsSet } from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcFileDropzone from '@atoms/ac-dropzone-file/ac-dropzone-file.web';
import AcCheckbox from '@atoms/ac-checkbox/ac-checkbox.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';

const _CLASSES = {
	MAIN: 'ac-upload-pile-list-modal',
	CONTENT: 'ac-upload-pile-list-modal__content',
};

const AcUploadPileListModal = ({
	store: { ui, projects, companies },
	data,
	submit,
	callback,
}) => {
	const navigate = useNavigate();
	const { can, cannot } = usePermissions();

	let raw_fields = {
		extended: false,
	};
	let raw_errors = {
		extended: null,
	};

	const [file, setFile] = useState(null);
	const [fields, setFields] = useState(raw_fields);
	const [errors, setErrors] = useState(raw_errors);

	const { hasErrors, handleInputChange, handleInputValidation } =
		useFormActions({
			fields,
			setFields,
			errors,
			setErrors,
		});

	useEffect(() => {}, []);

	const handleCancel = async (event) => {
		if (event && event.preventDefault) event.preventDefault();
		await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
	};

	const handleSubmit = (event) => {
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.persist) event.persist();

		if (submit && AcIsSet(file)) {
			const formData = new FormData();
			formData.append('file', file);
			formData.append('extended', fields.extended ? 1 : 0);

			const { id: project_id } = data;

			submit(project_id, formData, file.name).then((response) => {
				ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
			});
		}
	};

	const handleFileChange = (file) => {
		setFile(file);
	};

	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN]);
	}, []);

	const renderDropzone = useMemo(() => {
		if (!AcIsSet(data)) return null;

		return (
			<AcFileDropzone
				callback={handleFileChange}
				loading={projects.is_busy}
				accept={{
					'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': [
						'.xls',
						'.xlsx',
					],
					'application/csv': ['.csv'],
					'application/excel': ['.xls', '.xlsx'],
					'application/vnd.ms-excel': ['.xls', '.xlsx'],
					'text/csv': ['.csv'],
				}}
			/>
		);
	}, [data, projects.is_busy]);

	const getExtendedCheckboxOptions = useMemo(() => {
		return {
			type: TYPES.BOOLEAN,
			name: 'extended',
			value: true,
			callback: handleInputChange,
			checked: fields.extended,
		};
	}, [fields, fields.extended]);

	const getCancelButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.OMEGA,
			variant: VARIANTS.TEXT,
			title: 'Cancel',
			callback: handleCancel,
		};
	});

	const getSubmitButtonOptions = useMemo(() => {
		return {
			type: TYPES.SUBMIT,
			theme: THEMES.ALPHA,
			disabled: hasErrors,
			title: 'Save',
			disabled: !AcIsSet(file),
			callback: handleSubmit,
		};
	}, [fields, file]);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				<form
					method={'post'}
					onSubmit={handleSubmit}
					encType={'multipart/form-data'}
				>
					<AcContainer fluid>
						<AcRow>
							<AcColumn>{renderDropzone}</AcColumn>
						</AcRow>

						<AcRow>
							<AcColumn>
								<AcCheckbox {...getExtendedCheckboxOptions}>
									<span
										dangerouslySetInnerHTML={{
											__html: 'Extend (existing) pile list',
										}}
									/>
								</AcCheckbox>
							</AcColumn>
						</AcRow>

						<AcRow className={'h-margin-top-20'}>
							<AcColumn
								xxs={12}
								xs={7}
								sm={6}
								className={'h-text--align-left h-flex-v-align-center'}
							>
								<AcButton {...getCancelButtonOptions}>
									<span>Cancel</span>
								</AcButton>
							</AcColumn>

							<AcColumn
								xxs={12}
								xs={5}
								sm={6}
								className={'h-text--align-right'}
							>
								<AcButton {...getSubmitButtonOptions}>
									<span>Save</span>
								</AcButton>
							</AcColumn>
						</AcRow>
					</AcContainer>
				</form>
			</div>

			{projects.is_busy && <AcLoader loading={true} cover />}
		</div>
	);
};

export default withStore(observer(AcUploadPileListModal));
