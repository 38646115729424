// Imports => Dependencies
import React, { cloneElement, memo } from 'react';
import clsx from 'clsx';

const AcTabPanel = ({ id, component, ...rest }) => {
	return (
		<div
			className={'ac-tab-panel'}
			key={`ac-tab-panel-${id}`}
			id={`ac-tab-panel-${id}`}
		>
			{cloneElement(component, { ...rest, id })}
		</div>
	);
};

export default memo(AcTabPanel);
