import React, { useEffect, useCallback, useRef, useMemo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { KEYS } from '@constants';
import AcLoader from '@atoms/ac-loader/ac-loader.web';
import { AcCompanySearch } from '@components/ac-company-search';

const AcSelectPortalCompany = ({ store: { companies }, prefill }) => {
  const { current_companies_list, current_company, query, is_loading } =
    companies;

  const didMount = useRef(false);

  useEffect(() => {
    if (didMount.current) {
      if (query) companies.list();
    } else {
      didMount.current = true;
      companies.resetParams();
    }
  }, [query]);

  const handleOnFilterChange = useCallback((filterValue) => {
    companies.setQuery(filterValue);
    if (!query && !prefill) {
      companies.reset('company');
    }
    if (!filterValue) {
      companies.resetParams();
    }
    if (query && filterValue !== undefined && filterValue !== query) {
      if (current_company) {
        companies.reset('company');
      }
    }
  });

  const handleSelectCompany = useCallback(
    (item) => {
      if (current_companies_list && item) {
        companies.set(
          KEYS.COMPANY,
          current_companies_list.find((company) => company.id === item.id)
        );
      } else {
        companies.resetParams();
        companies.reset('company');
      }
    },
    [current_companies_list]
  );

  return (
    <div className={'ac-wizard-content'}>
      <div className={'ac-wizard-step--header'}>
        <div className={'ac-wizard-step--fill-h'}>
          Select the company you want to assign the configuration to.
        </div>
      </div>

      <AcCompanySearch
        value={current_company && current_company.name}
        onSelectItem={handleSelectCompany}
        results={current_companies_list}
        loading={is_loading}
        filter={handleOnFilterChange}
      />
      {is_loading && (
        <div className={'ac-wizard-loading'}>
          <AcLoader loading={true} cover />
        </div>
      )}
    </div>
  );
};

export default withStore(observer(AcSelectPortalCompany));
