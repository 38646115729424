// Imports => React
import React, { useEffect, useState, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import FileSaver from 'file-saver';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => Files
import PileListTemplateMonopile from '@assets/files/pilelists/MIQIP_pile_list_template_monopile.xlsx';
import PileListTemplatePinPile from '@assets/files/pilelists/MIQIP_pile_list_template_pin_pile.xlsx';
import PileListTemplateAnchorpile from '@assets/files/pilelists/MIQIP_pile_list_template_anchor_pile.xlsx';
import PileListTemplateOpenEndedpile from '@assets/files/pilelists/MIQIP_pile_list_template_open_ended_pile.xlsx';
import PileListTemplateClosedEndedpile from '@assets/files/pilelists/MIQIP_pile_list_template_closed_ended_pile.xlsx';

// Imports => Constants
import {
	DATETIME_FORMATS,
	KEYS,
	PERMISSIONS,
	ROUTES,
	THEMES,
	TYPES,
	VARIANTS,
	VISUALS,
} from '@constants';

// Imports => Utilities
import { AcIsSet, AcFormatDate } from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Molecules
import AcCheckPermissions from '@molecules/ac-check-permissions/ac-check-permissions.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
const AcRichContent = loadable(() =>
	import('@atoms/ac-rich-content/ac-rich-content.web')
);
const AcFileDropzone = loadable(() =>
	import('@atoms/ac-dropzone-file/ac-dropzone-file.web')
);
const AcSelectBox = loadable(() =>
	import('@atoms/ac-select-box/ac-select-box.web')
);
const AcTextInput = loadable(() =>
	import('@atoms/ac-text-input/ac-text-input.web')
);
const AcButton = loadable(() => import('@atoms/ac-button/ac-button.web'));
const AcLoader = loadable(() => import('@atoms/ac-loader/ac-loader.web'));

const _CLASSES = {
	MAIN: 'ac-add-pile-list-modal',
	CONTENT: 'ac-add-pile-list-modal__content',
};

const AcImportPileListModal = ({
	store: { ui, projects },
	instance = null,
	data,
	submit,
	callback,
}) => {
	const navigate = useNavigate();
	const { can, cannot } = usePermissions();

	let raw_fields = {
		file: null,
	};
	let raw_errors = {
		file: null,
	};

	const [file, setFile] = useState(null);
	const [fields, setFields] = useState(raw_fields);
	const [errors, setErrors] = useState(raw_errors);

	const { hasErrors, handleInputChange, handleInputValidation } =
		useFormActions({
			fields,
			setFields,
			errors,
			setErrors,
		});

	const handleCancel = async (event) => {
		if (event && event.preventDefault) event.preventDefault();
		await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
	};

	const handleSubmit = (event) => {
		if (event && event.preventDefault) event.preventDefault();
		if (event && event.persist) event.persist();

		if (submit) {
			const formData = new FormData();

			formData.append('_method', 'PUT');
			formData.append('name', data.name);

			if (AcIsSet(file)) {
				formData.append('file', file);
			}

			submit(formData, file?.name);
		}
	};

	const getPileListTemplate = useMemo(() => {
		switch (parseInt(data.type, 10)) {
			case 1: // monopile
				return {
					file: PileListTemplateMonopile,
					filename: 'MIQIP_pile_list_template_monopile.xlsx',
				};
				break;

			case 2: // pin pile
				return {
					file: PileListTemplatePinPile,
					filename: 'MIQIP_pile_list_template_pin_pile.xlsx',
				};
				break;

			case 3: // anchor pile
				return {
					file: PileListTemplateAnchorpile,
					filename: 'MIQIP_pile_list_template_anchor_pile.xlsx',
				};
				break;

			case 4: // open-ended pile
				return {
					file: PileListTemplateOpenEndedpile,
					filename: 'MIQIP_pile_list_template_open_ended_pile.xlsx',
				};
				break;

			case 5: // closed-ended pile
				return {
					file: PileListTemplateClosedEndedpile,
					filename: 'MIQIP_pile_list_template_closed_ended_pile.xlsx',
				};
				break;
		}

		return null;
	}, [data, data.type]);

	const handleDownloadTemplate = async () => {
		console.log('getPileListTemplate', getPileListTemplate);
		if (!getPileListTemplate?.file) return;

		await FileSaver.saveAs(
			getPileListTemplate?.file,
			getPileListTemplate?.filename
		);
	};

	const handleFileChange = (file) => {
		setFile(file);
	};

	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN]);
	}, []);

	const renderDropzone = useMemo(() => {
		return (
			<AcFileDropzone
				callback={handleFileChange}
				loading={instance && instance.is_busy}
				subtext={'*.XLS or *.XLSX file'}
			/>
		);
	}, [instance]);

	const getCancelButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.OMEGA,
			variant: VARIANTS.TEXT,
			title: 'Cancel',
			callback: handleCancel,
		};
	});

	const getSubmitButtonOptions = useMemo(() => {
		return {
			type: TYPES.SUBMIT,
			theme: THEMES.ALPHA,
			title: 'Continue',
			disabled: hasErrors,
			callback: handleSubmit,
		};
	}, [data, fields, errors, hasErrors, file]);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				<form
					method={'post'}
					onSubmit={handleSubmit}
					encType={'multipart/form-data'}
				>
					<AcContainer fluid>
						<AcRow className={'h-margin-top-5 h-margin-bottom-20'}>
							<AcColumn>
								<div className={'ac-rich-content'}>
									<p>
										Please use the correct{' '}
										<a href="javascript:;" onClick={handleDownloadTemplate}>
											template
										</a>
										. Pile list <strong>{data?.name}</strong> will be
										overwritten.
									</p>
								</div>
							</AcColumn>
						</AcRow>

						<AcRow className={'h-margin-top-5 h-margin-bottom-20'}>
							<AcColumn>{renderDropzone}</AcColumn>
						</AcRow>

						<AcRow className={'h-margin-top-20'}>
							<AcColumn
								xxs={12}
								xs={7}
								sm={6}
								className={'h-text--align-left h-flex-v-align-center'}
							>
								<AcButton {...getCancelButtonOptions}>
									<span>Cancel</span>
								</AcButton>
							</AcColumn>

							<AcColumn
								xxs={12}
								xs={5}
								sm={6}
								className={'h-text--align-right'}
							>
								<AcButton {...getSubmitButtonOptions}>
									<span>Continue</span>
								</AcButton>
							</AcColumn>
						</AcRow>
					</AcContainer>
				</form>
			</div>

			{instance && instance.is_busy && <AcLoader loading={true} cover />}
		</div>
	);
};

export default withStore(observer(AcImportPileListModal));
