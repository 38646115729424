import React, { useCallback, useEffect, useMemo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import { TYPES, ICONS, VARIANTS, SIZES, THEMES } from '@constants';
import AcEmptyBlock from '@atoms/ac-empty-block/ac-empty-block.web';
import { AcSelectRow, AcSelectColumn } from './ac-select-row';
import AcButton from '@atoms/ac-button/ac-button.web';

const selectButton = (callback) => ({
  type: TYPES.BUTTON,
  theme: THEMES.OMEGA,
  variant: VARIANTS.TEXT,
  size: SIZES.SMALL,
  title: '',
  callback,
});

const AcSelectEquipment = ({ store: { erp } }) => {
  const {
    currentSelectedContracts,
    current_contracts,
    addSelectedEquipment,
    removeFromSelectedEquipments,
    addAllSelectedEquipments,
    removeAllSelectedEquipments,
    current_selected_equipments,
    hasSelectedUnavailableEquipments,
    resetEquipments,
  } = erp;

  const onSelectItem = useCallback(
    (order_number, object_no, is_available) => ({ selected }) => {
      if (selected) {
        addSelectedEquipment(object_no, is_available);
      } else {
        removeFromSelectedEquipments(object_no);
      }
    },
    []
  );

  const matchSelectedEquipments = useMemo(() => {
    if (!currentSelectedContracts) return null;
    if (!current_contracts) return null;
    return current_contracts
      .slice()
      .filter((cc) => {
        return currentSelectedContracts
          .slice()
          .find((sc) => cc.order_number === sc);
      })
      .map((found) => ({ ...found }));
  }, [currentSelectedContracts, current_contracts]);

  const _addAllSelectedEquipments = useCallback(
    (includeUnavailable) =>
      addAllSelectedEquipments(includeUnavailable, matchSelectedEquipments),
    [matchSelectedEquipments]
  );

  const renderSelectedContracts = useMemo(() => {
    if (!matchSelectedEquipments || !matchSelectedEquipments.length)
      return <AcEmptyBlock message={'No equipments found to display.'} />;
    let numLinesWithObjects = 0;
    const equipmentRows = matchSelectedEquipments.map(
      (selectedContract, idx) => {
        const { order_number, lines } = selectedContract;
        if (lines && lines.length) {
          numLinesWithObjects++;
        }
        return lines
          .slice()
          .map(
            (
              { ppcb, group, type, object_no, is_available, serie },
              lineIdx
            ) => {
              let _isSelected = current_selected_equipments.find((sc) => {
                return sc === object_no;
              });

              return (
                <AcSelectRow
                  onHover={
                    (!is_available && (
                      <div className={'ac-select-row--message'}>
                        After confirmation Admin will be notified for
                        missing type. Current type will not be imported.
                      </div>
                    )) ||
                    null
                  }
                  key={`row-${idx}-${lineIdx}`}
                  item={{ ...selectedContract }}
                  disabled={!is_available}
                  selected={_isSelected ? true : false}
                  onSelect={onSelectItem(
                    order_number,
                    object_no,
                    is_available
                  )}
                  icon={is_available ? ICONS.CHECK : ICONS.ALERT_CIRCLE}
                >
                  <AcSelectColumn
                    header={'equipment group'}
                    data={group || '-'}
                  />
                  <AcSelectColumn header={'type'} data={type} />
                  <AcSelectColumn
                    header={serie && 'series'}
                    data={serie || ''}
                  />
                  <AcSelectColumn header={'object no'} data={object_no} />
                  {!ppcb && <AcSelectColumn header={''} data={''} />}
                  {ppcb && (
                    <AcSelectColumn header={'ppcb'} data={ppcb || '-'} />
                  )}
                </AcSelectRow>
              );
            }
          );
      }
    );
    return numLinesWithObjects > 0 ? (
      equipmentRows
    ) : (
      <AcEmptyBlock message={'No equipments found to display.'} />
    );
  }, [matchSelectedEquipments, current_selected_equipments]);

  useEffect(() => {
    resetEquipments();
    _addAllSelectedEquipments(false);
  }, []);

  return (
    <div className={'h-margin-top-20'}>
      <div className={'ac-wizard-step--header'}>
        {hasSelectedUnavailableEquipments && (
          <div className={'ac-wizard-step--fill-h'}>
            <div className={'ac-wizard-step--badge'}>
              Unavailable Type(s) selected!&nbsp;
            </div>
            <span></span>
          </div>
        )}
        <AcButton {...selectButton(() => _addAllSelectedEquipments(true))}>
          <span className={'ac-wizard-step--linkbutton'}>Select all</span>
        </AcButton>
        <AcButton {...selectButton(removeAllSelectedEquipments)}>
          <span className={'ac-wizard-step--linkbutton'}>Clear all</span>
        </AcButton>
      </div>

      <div className={'ac-wizard-step--content'}>
        {renderSelectedContracts}
      </div>
    </div>
  );
}

export default withStore(observer(AcSelectEquipment))