import React, { useState, useEffect, useMemo, memo } from 'react';
import ReactDOM from 'react-dom';

// Imports => Utilities
import {
  AcIsSet,
  AcIsFunction,
  AcIsUndefined,
  AcIsString,
  AcIsBoolean,
  AcGetTypeOf,
} from '@utils';

const AcCopyStyles = (source, target) => {
  if (!AcIsSet(source) || !AcIsSet(source.styleSheets)) return;

  Array.from(source.styleSheets).forEach((styleSheet) => {
    if (!AcIsSet(styleSheet)) return;
    // For <style> elements
    let rules;
    try {
      rules = styleSheet.rules || styleSheet.cssRules;
    } catch (e) {
      console.warn("Can't read the css rules of: " + styleSheet.href, e);
      return;
    }

    if (AcIsSet(rules)) {
      const newStyleEl = source.createElement('style');

      // Write the text of each rule into the body of the style element
      Array.from(styleSheet.cssRules).forEach((cssRule) => {
        const { cssText, type } = cssRule;
        let returnText = cssText;
        // Check if the cssRule type is CSSImportRule (3) or CSSFontFaceRule (5) to handle local imports on a about:blank page
        // '/custom.css' turns to 'http://my-site.com/custom.css'
        if ([3, 5].includes(type)) {
          returnText = cssText
            .split('url(')
            .map((line) => {
              if (line[1] === '/') {
                return `${line.slice(0, 1)}${
                  window.location.origin
                }${line.slice(1)}`;
              }
              return line;
            })
            .join('url(');
        }
        newStyleEl.appendChild(source.createTextNode(returnText));
      });

      target.head.appendChild(newStyleEl);
    } else if (styleSheet.href) {
      // for <link> elements loading CSS from a URL
      const newLinkEl = source.createElement('link');

      newLinkEl.rel = 'stylesheet';
      newLinkEl.href = styleSheet.href;
      target.head.appendChild(newLinkEl);
    }
  });
};

const AcConvertToWindowFeatures = (obj) => {
  return Object.keys(obj)
    .reduce((features, name) => {
      const value = obj[name];
      if (AcIsBoolean(value)) {
        features.push(`${name}=${value ? 'yes' : 'no'}`);
      } else {
        features.push(`${name}=${value}`);
      }
      return features;
    }, [])
    .join(',');
};

const AcNewWindow = ({
  url = '',
  name = '',
  title = '',
  features = { width: 1024, height: 768 },
  onOpen = null,
  onExit = null,
  center = 'screen',
  copyStyles = true,
  children,
}) => {
  let $container = document.createElement('div');
  let $window = null;
  let windowCheckerInterval = null;
  let released = false;
  let mounted = false;

  const openChild = () => {
    // Prepare position of the new window to be centered against the 'parent' window or 'screen'.
    if (
      AcIsString(center) &&
      (AcIsUndefined(features.width) || AcIsUndefined(features.height))
    ) {
      console.warn(
        'width and height window features must be present when a center prop is provided'
      );
    } else if (center === 'parent') {
      features.left =
        window.top.outerWidth / 2 + window.top.screenX - features.width / 2;
      features.top =
        window.top.outerHeight / 2 + window.top.screenY - features.height / 2;
    } else if (center === 'screen') {
      const screenLeft =
        window.screenLeft !== undefined
          ? window.screenLeft
          : window.screen.left;
      const screenTop =
        window.screenTop !== undefined ? window.screenTop : window.screen.top;

      const width = window.innerWidth
        ? window.innerWidth
        : document.documentElement.clientWidth
        ? document.documentElement.clientWidth
        : window.screen.width;
      const height = window.innerHeight
        ? window.innerHeight
        : document.documentElement.clientHeight
        ? document.documentElement.clientHeight
        : window.screen.height;

      features.left = width / 2 - features.width / 2 + screenLeft;
      features.top = height / 2 - features.height / 2 + screenTop;
    }

    features.width = `${features.width}px`;
    features.height = `${features.height}px`;

    // Open a new window.
    $window = window.open(url, name, AcConvertToWindowFeatures(features));

    // When a new window use content from a cross-origin there's no way we can attach event
    // to it. Therefore, we need to detect in a interval when the new window was destroyed
    // or was closed.
    windowCheckerInterval = setInterval(() => {
      if (!$window || $window.closed) release();
    }, 50);

    // Check if the new window was succesfully opened.
    if ($window) {
      $window.document.title = title;
      $window.document.body.appendChild($container);

      // If specified, copy styles from parent window's document.
      if (copyStyles) {
        setTimeout(() => AcCopyStyles(document, $window.document), 0);
      }

      if (AcIsFunction(onOpen)) onOpen($window);

      // Release anything bound to this component before the new window unload.
      $window.onbeforeunload = (event) => {
        if (event?.detail) ReactDOM.unmountComponentAtNode(event.detail);
        if (AcIsSet(window._lvTimer)) clearTimeout(window._lvTimer);
        if (AcIsSet(window._lvProgress)) clearTimeout(window._lvProgress);
        release();
      };
    }
  };

  /**
   * Release the new window and anything that was bound to it.
   */
  const release = () => {
    clearInterval(windowCheckerInterval);
    if (AcIsSet(onExit)) onExit(null);
  };

  useEffect(() => {
    openChild();
    return () => release();
  }, []);

  const renderWindow = useMemo(() => {
    return ReactDOM.createPortal(children, $container);
  }, [children, onExit]);

  return renderWindow;
};

export default memo(AcNewWindow);
