import React, { useEffect, useMemo, useCallback } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import {
  TYPES,
  DATETIME_FORMATS,
  KEYS,
  ICONS,
  VARIANTS,
  SIZES,
  THEMES,
} from '@constants';
import AcEmptyBlock from '@atoms/ac-empty-block/ac-empty-block.web';
import AcLoader from '@atoms/ac-loader/ac-loader.web';
import { AcSelectRow, AcSelectColumn } from './ac-select-row';
import AcButton from '@atoms/ac-button/ac-button.web';
import { AcFormatDate } from '@utils';

const selectButton = (callback) => ({
  type: TYPES.BUTTON,
  theme: THEMES.OMEGA,
  variant: VARIANTS.TEXT,
  size: SIZES.SMALL,
  title: '',
  callback,
});

const AcSelectContract = ({ store: { erp } }) => {
  const {
    current_contracts,
    addSelectedContract,
    removeFromSelectedContracts,
    addAllSelectedContracts,
    removeAllSelectedContracts,
    currentSelectedContracts,
    current_company,
    is_loading,
  } = erp;

  useEffect(() => {
    if (
      current_company &&
      current_company.erp_id &&
      !currentSelectedContracts.length
    ) {
      erp.listAllContracts();
    }
  }, [current_company]);

  const onSelectItem = useCallback(
    ({ item: { order_number }, selected }) => {
      if (selected) {
        addSelectedContract(order_number);
      } else {
        removeFromSelectedContracts(order_number);
      }
    },
    [currentSelectedContracts]
  );

  const selectAllContracts = useCallback(() => {
    addAllSelectedContracts();
  }, []);

  const removeAllContracts = useCallback(() => {
    removeAllSelectedContracts();
  }, []);

  const renderData = useMemo(() => {
    if (!current_contracts || !current_contracts.length)
      return <AcEmptyBlock message={'No contracts found to display.'} />;

    const data = current_contracts.map((contractData, idx) => {
      const { end_date, start_date, order_number } = contractData;
      let _isSelected = currentSelectedContracts.find((sc) => {
        return sc === order_number;
      });

      const _startDate = start_date
        ? AcFormatDate(start_date, null, DATETIME_FORMATS.LOCAL_DATE)
        : null;
      const _endDate = end_date
        ? AcFormatDate(end_date, null, DATETIME_FORMATS.LOCAL_DATE)
        : null;

      return (
        <AcSelectRow
          key={`row-${idx}`}
          item={{ ...contractData }}
          selected={_isSelected ? true : false}
          icon={ICONS.CHECK}
          onSelect={onSelectItem}
        >
          <AcSelectColumn header={'Order nr.'} data={order_number} />
          <AcSelectColumn header={'First day'} data={_startDate || '-'} />
          <AcSelectColumn header={'Last day'} data={_endDate || '-'} />
        </AcSelectRow>
      );
    });

    return data;
  }, [current_contracts, currentSelectedContracts]);

  return (
    <div className={'h-margin-top-20'}>
      {is_loading && (
        <div className={'ac-wizard-loading'}>
          <AcLoader loading={true} />
        </div>
      )}
      {!is_loading && (
        <>
          <div className={'ac-wizard-step--header'}>
            {current_contracts && (
              <div className={'ac-wizard-step--fill-h'}>
                <div className={'ac-wizard-step--badge'}>
                  {current_contracts.length} contracts found.
                </div>
              </div>
            )}

            <AcButton {...selectButton(selectAllContracts)}>
              <span className={'ac-wizard-step--linkbutton'}>
                Select all
              </span>
            </AcButton>
            <AcButton {...selectButton(removeAllContracts)}>
              <span className={'ac-wizard-step--linkbutton'}>
                Clear all
              </span>
            </AcButton>
          </div>
          <div className={'ac-wizard-step--content'}>{renderData}</div>
        </>
      )}
    </div>
  );
}

export default withStore(observer(AcSelectContract))