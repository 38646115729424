// Imports => React
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import ReactTooltip from 'react-tooltip';
import loadable from '@loadable/component';
import clsx from 'clsx';

// Imports => Files
import PileListTemplateMonopile from '@assets/files/pilelists/MIQIP_pile_list_template_monopile.xlsx';
import PileListTemplatePinPile from '@assets/files/pilelists/MIQIP_pile_list_template_pin_pile.xlsx';
import PileListTemplateAnchorpile from '@assets/files/pilelists/MIQIP_pile_list_template_anchor_pile.xlsx';
import PileListTemplateOpenEndedpile from '@assets/files/pilelists/MIQIP_pile_list_template_open_ended_pile.xlsx';
import PileListTemplateClosedEndedpile from '@assets/files/pilelists/MIQIP_pile_list_template_closed_ended_pile.xlsx';

// Imports => Constants
import {
	DATETIME_FORMATS,
	KEYS,
	PERMISSIONS,
	THEMES,
	TYPES,
	VARIANTS,
	VISUALS,
} from '@constants';

// Imports => Utilities
import { AcIsSet, AcIsEmptyString, AcFormatDate } from '@utils';

// Imports => Hooks
import { usePermissions, useFormActions } from '@hooks';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
const AcHeading = loadable(() => import('@atoms/ac-heading/ac-heading.web'));
const AcRichContent = loadable(() =>
	import('@atoms/ac-rich-content/ac-rich-content.web')
);
const AcAlert = loadable(() => import('@atoms/ac-alert/ac-alert.web'));
const AcCheckbox = loadable(() => import('@atoms/ac-checkbox/ac-checkbox.web'));
const AcButton = loadable(() => import('@atoms/ac-button/ac-button.web'));
const AcLoader = loadable(() => import('@atoms/ac-loader/ac-loader.web'));

const _CLASSES = {
	MAIN: 'ac-select-overwrite-or-extend-modal',
	CONTENT: 'ac-select-overwrite-or-extend-modal__content',
};

let delay = null;

const AcSelectOverwriteOrExtendModal = ({
	store: { ui },
	data,
	submit,
	callback,
}) => {
	const { can, cannot } = usePermissions();

	let raw_fields = {
		method: null,
	};
	let raw_errors = {
		method: undefined,
	};

	const [fields, setFields] = useState(raw_fields);
	const [errors, setErrors] = useState(raw_errors);

	const { hasErrors, handleInputChange, handleInputValidation } =
		useFormActions({
			fields,
			setFields,
			errors,
			setErrors,
		});

	const handleCancel = async (event) => {
		if (event && event.preventDefault) event.preventDefault();
		await ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);
	};

	const handleSubmit = (event) => {
		if (event && event.preventDefault) event.preventDefault();

		if (submit)
			submit(data, fields).then(() =>
				ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false)
			);
	};

	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN]);
	}, []);

	const getOverwriteRadioButtonOptions = useMemo(() => {
		return {
			type: TYPES.RADIO,
			name: 'method',
			value: 'overwrite',
			callback: handleInputChange,
			validation: handleInputValidation,
			checked: fields.method === 'overwrite',
		};
	}, [fields, fields.method]);

	const getExtendRadioButtonOptions = useMemo(() => {
		return {
			type: TYPES.RADIO,
			name: 'method',
			value: 'extend',
			callback: handleInputChange,
			validation: handleInputValidation,
			checked: fields.method === 'extend',
		};
	}, [fields, fields.method]);

	const getCancelButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.OMEGA,
			variant: VARIANTS.TEXT,
			title: 'Cancel',
			callback: handleCancel,
		};
	});

	const getSubmitButtonOptions = useMemo(() => {
		return {
			type: TYPES.BUTTON,
			theme: THEMES.ALPHA,
			disabled: !fields.method,
			callback: handleSubmit,
		};
	}, [fields, fields.method, errors, hasErrors, handleSubmit]);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				<AcContainer fluid>
					<AcRow>
						<AcColumn>
							<AcRichContent
								className={'h-margin-top-0 h-margin-bottom-0'}
								content={`<p>Do you want to <strong>overwrite</strong> or <strong>extend</strong>?</p>`}
							/>
						</AcColumn>
					</AcRow>

					<AcRow className={'h-margin-top-5'}>
						<AcColumn xs={12} sm={6} md={4}>
							<AcCheckbox {...getOverwriteRadioButtonOptions} radio>
								<span
									dangerouslySetInnerHTML={{
										__html: 'Overwrite',
									}}
								/>
							</AcCheckbox>
						</AcColumn>

						<AcColumn xs={12} sm={6} md={4}>
							<AcCheckbox {...getExtendRadioButtonOptions} radio>
								<span
									dangerouslySetInnerHTML={{
										__html: 'Extend',
									}}
								/>
							</AcCheckbox>
						</AcColumn>
					</AcRow>

					<AcRow className={'h-margin-top-10 h-margin-bottom-0'}>
						<AcColumn>
							<AcAlert
								theme={THEMES.INFO_LIGHT}
								content="<p><strong>Overwrite</strong> will replace the current pile list for this project.<br/></p><p><strong>Extend</strong> will add the new pile list to the current pile list. For duplicates, the latest information will be stored.</p>"
							/>
						</AcColumn>
					</AcRow>

					<AcRow className={'h-margin-top-20'}>
						<AcColumn
							xxs={12}
							xs={7}
							sm={6}
							className={'h-text--align-left h-flex-v-align-center'}
						>
							<AcButton {...getCancelButtonOptions}>
								<span>Cancel</span>
							</AcButton>
						</AcColumn>

						<AcColumn xxs={12} xs={5} sm={6} className={'h-text--align-right'}>
							<AcButton {...getSubmitButtonOptions}>
								<span>Continue</span>
							</AcButton>
						</AcColumn>
					</AcRow>
				</AcContainer>
			</div>
		</div>
	);
};

export default withStore(observer(AcSelectOverwriteOrExtendModal));
