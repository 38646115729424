// Imports => React
import React, { useEffect, useState, useMemo } from 'react';
import { withStore } from '@stores';
import { observer } from 'mobx-react-lite';
import clsx from 'clsx';

// Imports => Constants
import { ICONS, KEYS, THEMES, TITLES, TYPES, VARIANTS } from '@constants';

// Imports => Utilities
import {
  AcIsSet,
  AcIsArray,
  AcIsObject,
  AcGetEquipmentIcon,
  AcSortBy,
} from '@utils';

// Imports => Molecules
import AcExpandable from '@molecules/ac-expandable/ac-expandable.web';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcDivider from '@atoms/ac-divider/ac-divider.web';
import AcHeading from '@atoms/ac-heading/ac-heading.web';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';
import AcButton from '@atoms/ac-button/ac-button.web';
import { AcFormatInternalURI } from '../../utilities/ac-format-internal-uri';
import { Link } from 'react-router-dom';

const _CLASSES = {
  MAIN: 'ac-resource-linked-to-modal',
  CONTENT: 'ac-resource-linked-to-modal__content',
  SECTION: {
    MAIN: 'ac-resource-linked-to-modal__section',
  },
  ROW: {
    MAIN: 'ac-resource-linked-to-modal__row',
    ITEM: 'ac-resource-linked-to-modal__row__item',
    LINK: 'ac-resource-linked-to-modal__row__link',
  },
};

const AcResourceLinkedToModal = ({ store: { ui }, errors = [], callback }) => {
  const [sections, setSections] = useState({});

  useEffect(() => {
    let result = {};

    for (let n in errors) {
      result[n] = false;
    }

    setSections(result);
  }, [errors]);

  const closeModal = () => ui.setValue(KEYS.MODAL, KEYS.VISIBLE, false);

  const handleSubmit = (event) => {
    if (event && event.persist) event.persist();
    if (event && event.preventDefault) event.preventDefault();
    if (callback) callback(event);
    closeModal();
  };

  const handleExpandableCallback = (state, key) => {
    const collection = Object.assign({}, sections);

    if (state === true) {
      for (let n in collection) {
        if (n === key) collection[n] = true;
        else collection[n] = false;
      }
    } else {
      collection[key] = false;
    }

    setSections(collection);
  };

  const getRowItemClassNames = useMemo(() => {
    return clsx(_CLASSES.ROW.ITEM);
  }, []);

  const getRowClassNames = useMemo(() => {
    return clsx(_CLASSES.ROW.MAIN);
  }, []);

  const getSectionClassNames = useMemo(() => {
    return clsx(_CLASSES.SECTION.MAIN);
  }, []);

  const getContentClassNames = useMemo(() => {
    return clsx([_CLASSES.CONTENT]);
  }, []);

  const getStyleClassNames = useMemo(() => {
    return clsx([_CLASSES.MAIN]);
  }, []);

  const getGroupTitle = (key) => {
    let result = 'Various';

    switch (key) {
      case KEYS.PROJECTS:
        result = TITLES.PROJECTS;
        break;

      case KEYS.EQUIPMENT:
        result = TITLES.EQUIPMENT;
        break;

      case KEYS.CONFIGURATIONS:
        result = TITLES.CONFIGURATIONS;
        break;

      case KEYS.CONTRACTS:
        result = TITLES.CONTRACTS;
        break;

      case KEYS.USERS:
        result = TITLES.USERS;
        break;

      case KEYS.COMPANIES:
        result = TITLES.COMPANIES;
        break;

      case KEYS.EQUIPMENT_TYPES:
        result = TITLES.EQUIPMENT_TYPES;
        break;

      default:
    }

    return result;
  };

  const renderCompanyLine = (item, key) => {
    return (
      <AcRow key={`linked-${key}-${item.id}`} className={getRowClassNames}>
        <AcColumn xs={12} sm={12} className={getRowItemClassNames}>
          <p
            dangerouslySetInnerHTML={{
              __html: item.name,
            }}
          />
        </AcColumn>
      </AcRow>
    );
  };

  const renderConfigurationLine = (item, key) => {
    return (
      <AcRow key={`linked-${key}-${item.id}`} className={getRowClassNames}>
        <AcColumn xs={12} sm={6} className={getRowItemClassNames}>
          <p
            dangerouslySetInnerHTML={{
              __html: item.name,
            }}
          />
        </AcColumn>
        <AcColumn xs={12} sm={6} className={getRowItemClassNames}>
          <p
            dangerouslySetInnerHTML={{
              __html: item.company_name || '-',
            }}
          />
        </AcColumn>
      </AcRow>
    );
  };

  const renderContractLine = (item, key) => {
    return (
      <AcRow key={`linked-${key}-${item.id}`} className={getRowClassNames}>
        <AcColumn xs={12} sm={6} className={getRowItemClassNames}>
          <p
            dangerouslySetInnerHTML={{
              __html: item.name,
            }}
          />
        </AcColumn>
        <AcColumn xs={12} sm={6} className={getRowItemClassNames}>
          <p
            dangerouslySetInnerHTML={{
              __html: item.company || '-',
            }}
          />
        </AcColumn>
      </AcRow>
    );
  };

  const renderEquipmentLine = (item, key) => {
    const icon = AcGetEquipmentIcon(item.group);

    return (
      <AcRow key={`linked-${key}-${item.id}`} className={getRowClassNames}>
        <AcColumn xs={12} sm={4} className={getRowItemClassNames}>
          <p
            dangerouslySetInnerHTML={{
              __html: `<i class="ac-icon ac-icon--${icon}"></i> ${item.object_no}`,
            }}
          />
        </AcColumn>
        <AcColumn xs={12} sm={4} className={getRowItemClassNames}>
          <p
            dangerouslySetInnerHTML={{
              __html: item.type || '',
            }}
          />
        </AcColumn>
        <AcColumn xs={12} sm={4} className={getRowItemClassNames}>
          <p
            dangerouslySetInnerHTML={{
              __html: item.group || '',
            }}
          />
        </AcColumn>
      </AcRow>
    );
  };

  const renderEquipmentTypeLine = (item, key) => {
    return (
      <AcRow key={`linked-${key}-${item.id}`} className={getRowClassNames}>
        <AcColumn xs={12} sm={6} className={getRowItemClassNames}>
          <p
            dangerouslySetInnerHTML={{
              __html: item.name,
            }}
          />
        </AcColumn>
        <AcColumn xs={12} sm={6} className={getRowItemClassNames}>
          <p
            dangerouslySetInnerHTML={{
              __html: item.company_name || '-',
            }}
          />
        </AcColumn>
      </AcRow>
    );
  };

  const renderProjectLine = (item, key) => {
    return (
      <AcRow key={`linked-${key}-${item.id}`} className={getRowClassNames}>
        <AcColumn xs={12} sm={6} className={getRowItemClassNames}>
          <p
            dangerouslySetInnerHTML={{
              __html: item.name,
            }}
          />
        </AcColumn>
        <AcColumn xs={12} sm={6} className={getRowItemClassNames}>
          <p
            dangerouslySetInnerHTML={{
              __html: item.company_name || '-',
            }}
          />
        </AcColumn>
      </AcRow>
    );
  };

  const renderUserLine = (item, key) => {
    return (
      <AcRow key={`linked-${key}-${item.id}`} className={getRowClassNames}>
        <AcColumn xs={12} sm={4} className={getRowItemClassNames}>
          <p
            dangerouslySetInnerHTML={{
              __html: item.name,
            }}
          />
        </AcColumn>
        <AcColumn xs={12} sm={4} className={getRowItemClassNames}>
          <p
            dangerouslySetInnerHTML={{
              __html: item.company_name || '-',
            }}
          />
        </AcColumn>
        <AcColumn xs={12} sm={4} className={getRowItemClassNames}>
          <p
            dangerouslySetInnerHTML={{
              __html: (item.roles && item.roles.join(', ')) || '-',
            }}
          />
        </AcColumn>
      </AcRow>
    );
  };

  const getRenderMethod = (key) => {
    let result = null;

    switch (key) {
      case KEYS.PROJECTS:
        result = renderProjectLine;
        break;

      case KEYS.EQUIPMENT:
        result = renderEquipmentLine;
        break;

      case KEYS.CONFIGURATIONS:
        result = renderConfigurationLine;
        break;

      case KEYS.CONTRACTS:
        result = renderContractLine;
        break;

      case KEYS.USERS:
        result = renderUserLine;
        break;

      case KEYS.COMPANIES:
        result = renderCompanyLine;
        break;

      case KEYS.EQUIPMENT_TYPES:
        result = renderEquipmentTypeLine;
        break;

      default:
    }

    return result;
  };

  const renderConnections = useMemo(() => {
    if (!AcIsSet(errors)) return null;
    if (!AcIsObject(errors)) return null;

    let result = [];

    const sorted = Object.keys(errors)
      .sort()
      .reduce((acc, key) => {
        acc[key] = errors[key];
        return acc;
      }, {});

    const renderResourceLink = (item, key) => {
      const link = {
        id: item.id,
        entity: item.group || key,
        ...(item.group && { equipment_group: item.group }),
      };
      const route = AcFormatInternalURI(link, 'Connected resource');
      return (component) => {
        return (
          <Link
            onClick={closeModal}
            key={item.id}
            to={route}
            className={clsx(_CLASSES.MAIN)}
          >
            <div className={_CLASSES.ROW.LINK}>{component}</div>
          </Link>
        );
      };
    };

    for (let key in sorted) {
      const group = sorted[key];
      const title = getGroupTitle(key);
      let row = [];

      let collection = group;

      if (key === KEYS.EQUIPMENT) {
        collection = AcSortBy({ collection, field: 'group' });
      }

      const len = collection.length;
      let n = 0;

      const renderMethod = getRenderMethod(key);

      if (!AcIsSet(renderMethod)) continue;

      for (n; n < len; n++) {
        const item = collection[n];
        const object = item.can_be_shown
          ? renderResourceLink(item, key)(renderMethod(item, key))
          : renderMethod(item, key);

        row.push(object);
      }

      const section = (
        <div key={`linked-group-${key}`} className={getSectionClassNames}>
          <AcExpandable
            id={key}
            title={
              <>
                <sub>{row.length}</sub> {title}
              </>
            }
            callback={handleExpandableCallback}
            open={sections[key]}
            limited
          >
            {row}
          </AcExpandable>
        </div>
      );

      result.push(section);
    }

    return result;
  }, [errors, sections]);

  const getSubmitButtonOptions = useMemo(() => {
    return {
      type: TYPES.BUTTON,
      theme: THEMES.ALPHA,
      title: 'Close',
      callback: handleSubmit,
    };
  }, [errors]);

  return (
    <div className={getStyleClassNames}>
      <div className={getContentClassNames}>
        <AcContainer fluid>
          <AcRow>
            <AcColumn>
              <AcRichContent
                content={
                  '<p>This resource is <strong>linked to</strong>, <strong>connected to</strong> or <strong>used in</strong> the following items.</p><p>Please <strong>review and/or remove these connections</strong> before permanently deleting this resource.</p>'
                }
              />
            </AcColumn>
          </AcRow>

          {renderConnections}

          <AcRow className={'h-margin-top-20'}>
            <AcColumn xxs={12} className={'h-text--align-right'}>
              <AcButton {...getSubmitButtonOptions}>
                <span>Close</span>
              </AcButton>
            </AcColumn>
          </AcRow>
        </AcContainer>
      </div>
    </div>
  );
};

export default withStore(observer(AcResourceLinkedToModal));
