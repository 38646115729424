// Imports => Dependencies
import React, { useEffect, useState, useCallback, useMemo, memo } from 'react';
import ReactCSSTransitionReplace from 'react-css-transition-replace';
import clsx from 'clsx';

// Imports => Constants
import { KEYS } from '@constants';

// Imports => Hooks
import { usePermissions } from '@hooks';

// Imports => Utilities
import { AcIsSet, AcSetHash, AcGetHash, AcRemoveHash } from '@utils';

// Imports => Molecules
import AcTabPanelContainer from '@molecules/ac-tab-panel-container/ac-tab-panel-container.web';
import AcTabPanel from '@molecules/ac-tab-panel/ac-tab-panel.web';

// Imports => Components
import AcTabBar from '@components/ac-tab-bar/ac-tab-bar.web';

const AcTabContainer = ({ collection, data, initial = null }) => {
  const [active, setActiveTab] = useState(initial || AcGetHash() || 'overview');
  const { can, cannot } = usePermissions();

  useEffect(() => {
    findActiveTab(active);

    if (!active) setActiveTab(collection[0].id);
  }, []);

  const findActiveTab = () => {
    const hash = AcGetHash();

    if (!AcIsSet(collection)) return;
    if (AcIsSet(initial)) {
      handleTabChange(initial);
      return;
    }

    const len = collection.length;
    let n = 0;
    let result = null;

    for (n; n < len; n++) {
      const item = collection[n];

      if (item.id === hash) {
        result = item.id;
      }
    }

    if (active === result) return;
    if (result) setActiveTab(result);
    else {
      handleTabChange(collection[0].id);
    }

    return result;
  };

  const handleTabChange = useCallback(
    (id) => {
      setActiveTab(id);
      AcSetHash(id);
    },
    [active]
  );

  const renderTabPanels = useMemo(() => {
    if (!AcIsSet(collection)) return null;

    const len = collection.length;
    let n = 0;
    let result = null;

    for (n; n < len; n++) {
      const item = collection[n];

      if (item.allowed && !can(item.allowed)) continue;

      if (item.id === active) {
        const { id, component } = item;
        const object = (
          <AcTabPanel
            key={`ac-tab-panel-${id}`}
            id={id}
            component={component}
            data={data}
          />
        );
        result = object;
        break;
      }
    }

    return result;
  }, [collection, data, active]);

  const renderTabBar = useMemo(() => {
    if (!AcIsSet(collection)) return null;
    if (!AcIsSet(active)) return null;
    if (collection.length === 0 || collection.length === 1) return null;

    const len = collection.length;
    let n = 0;
    let result = [];

    for (n; n < len; n++) {
      const item = collection[n];

      if (item.allowed && !can(item.allowed)) continue;

      result.push(item);
    }

    if (result.length === 0 || result.length === 1) return null;

    return (
      <AcTabBar tabs={result} active={active} callback={handleTabChange} />
    );
  }, [collection, active]);

  return (
    <div className={'ac-tab-container-wrp'}>
      <div className={'ac-tab-container'}>
        {renderTabBar}

        <ReactCSSTransitionReplace
          transitionName="fade-wait"
          transitionEnterTimeout={500}
          transitionLeaveTimeout={500}
          component={AcTabPanelContainer}
          childComponent={'div'}
        >
          <div key={`ac-tab-${active}`} className={'ac-tab-panels'}>
            {renderTabPanels}
          </div>
        </ReactCSSTransitionReplace>
      </div>
    </div>
  );
};

export default memo(AcTabContainer);
