// Imports => React
import React, { useEffect, useMemo } from 'react';
import clsx from 'clsx';

// Imports => Constants
import { ICONS } from '@constants';

// Imports => Atoms
import { AcContainer, AcRow, AcColumn } from '@atoms/ac-grid';
import AcRichContent from '@atoms/ac-rich-content/ac-rich-content.web';
import AcIcon from '@atoms/ac-icon/ac-icon.web';

const _CLASSES = {
	MAIN: 'ac-no-internet-connection-notice-modal',
	CONTENT: 'ac-no-internet-connection-notice-modal__content',
};

const AcNoInternetConnectionNoticeModal = ({}) => {
	useEffect(() => {
		return () => window.location.reload(false);
	}, []);

	const getContentClassNames = useMemo(() => {
		return clsx([_CLASSES.CONTENT]);
	}, []);

	const getStyleClassNames = useMemo(() => {
		return clsx([_CLASSES.MAIN]);
	}, []);

	return (
		<div className={getStyleClassNames}>
			<div className={getContentClassNames}>
				<AcContainer fluid className={'h-text--align-center'}>
					<AcRow>
						<AcColumn>
							<AcIcon
								icon={ICONS.WIFI_OFF}
								className={'h-margin-bottom-25 h-text--size-bigger'}
							/>
						</AcColumn>
					</AcRow>

					<AcRow className={'h-margin-top-25'}>
						<AcColumn>
							<AcRichContent
								content={
									'<p>There seems to be no internet connection. Check your connection and if neccessary reload this page.</p>'
								}
							/>
						</AcColumn>
					</AcRow>
				</AcContainer>
			</div>
		</div>
	);
};

export default AcNoInternetConnectionNoticeModal;
