// Imports => React
import React, { useRef, useMemo, memo } from 'react';
import clsx from 'clsx';

// Imports => Data
import { KEYBOARD_SHORTCUTS } from '@data/keyboard.data';

// Imports => Utilities
import { AcIsSet, AcUUID } from '@utils';

// Imports => Atoms
import { AcRow, AcColumn } from '@atoms/ac-grid';
import AcHeading from '@atoms/ac-heading/ac-heading.web';

const _CLASSES = {
	MAIN: 'ac-modal-keyboard-shortcuts-modal ac-modal-keyboard-shortcuts',
	GROUP: 'ac-modal-keyboard-shortcuts__group',
	TITLE: 'ac-modal-keyboard-shortcuts__title',
	LIST: {
		MAIN: 'ac-modal-keyboard-shortcuts__list',
		ITEM: 'ac-modal-keyboard-shortcuts__list__item',
		LABEL: 'ac-modal-keyboard-shortcuts__list__item-label',
		KEY: 'ac-modal-keyboard-shortcuts__list__item-key',
	},
};

const AcKeyboardShortcutsModal = ({ data = KEYBOARD_SHORTCUTS }) => {
	const $modal = useRef(null);

	const getListKeyClassNames = useMemo(() => {
		return clsx(_CLASSES.LIST.KEY);
	}, []);

	const getListLabelClassNames = useMemo(() => {
		return clsx(_CLASSES.LIST.LABEL);
	}, []);

	const getListItemClassNames = useMemo(() => {
		return clsx(_CLASSES.LIST.ITEM);
	}, []);

	const getListClassNames = useMemo(() => {
		return clsx(_CLASSES.LIST.MAIN);
	}, []);

	const getGroupTitleClassNames = useMemo(() => {
		return clsx(_CLASSES.TITLE);
	}, []);

	const getGroupClassNames = useMemo(() => {
		return clsx(_CLASSES.GROUP);
	}, []);

	const getMainClassNames = useMemo(() => {
		return clsx(_CLASSES.MAIN);
	}, []);

	const renderShortcuts = useMemo(() => {
		if (!AcIsSet(data)) return null;

		const collection = data;
		const len = collection.length;
		let n = 0;
		let result = [];

		for (n; n < len; n++) {
			const group = collection[n];
			const list = [];

			const { title, items } = group;

			const glen = items.length;
			let g = 0;

			for (g; g < glen; g++) {
				const item = items[g];

				const object = (
					<li
						key={`ac-keyboard-shortcut-${AcUUID()}`}
						className={getListItemClassNames}
					>
						<div className={getListLabelClassNames}>{item.label}</div>
						<div className={getListKeyClassNames}>
							<span>{item.key}</span>
						</div>
					</li>
				);

				list.push(object);
			}

			const col = (
				<AcColumn
					xs={12}
					sm={12}
					md={6}
					key={`ac-keyboard-shortcut-group-${AcUUID()}`}
				>
					<div className={getGroupClassNames}>
						<AcHeading rank={5} className={getGroupTitleClassNames}>
							{group.title}
						</AcHeading>
						<ul className={getListClassNames}>{list}</ul>
					</div>
				</AcColumn>
			);

			result.push(col);
		}

		return result;
	}, []);

	return (
		<div className={getMainClassNames} ref={$modal}>
			<AcRow>{renderShortcuts}</AcRow>
		</div>
	);
};

export default memo(AcKeyboardShortcutsModal);
