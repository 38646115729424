// Imports => React
import React, { useState, useMemo, useEffect, memo } from 'react';
import loadable from '@loadable/component';

// Imports => Constants
import { ICONS, KEYS, THEMES, TYPES, VARIANTS } from '@constants';

// Imports => Utilities
import {
  AcIsSet,
  AcIsString,
  AcIsArray,
  AcIsEmptyString,
  AcCapitalize,
  AcIsObject,
} from '@utils';

// Imports => Atoms
import { AcRow, AcColumn } from '@atoms/ac-grid';
const AcEmptyBlock = loadable(() =>
  import('@atoms/ac-empty-block/ac-empty-block.web')
);
const AcRichContent = loadable(() =>
  import('@atoms/ac-rich-content/ac-rich-content.web')
);
const AcSearchInput = loadable(() =>
  import('@atoms/ac-search-input/ac-search-input.web')
);
const AcLoader = loadable(() => import('@atoms/ac-loader/ac-loader.web'));

const AcMultiSelect = ({
  instance,
  collection,
  initialSelection,
  introduction = null,
  multiple = true,
  highlight,
  feedback,
  onChange,
  alwaysShowSearch,
}) => {
  const [query, setQuery] = useState('');
  const [selection, setSelection] = useState(initialSelection || []);

  const handleSelect = (id) => {
    let arr = (selection && selection.slice()) || [];

    if (multiple) {
      const index = arr.indexOf(id);

      if (index > -1) {
        arr.splice(index, 1);
      } else {
        arr.push(id);
      }
    } else {
      arr = [id];
    }

    console.warn({ id, arr });
    setSelection(arr);
    if (onChange) onChange(arr);
  };

  const handleSearch = (event, name, value, get = true) => {
    setQuery(value);
  };

  const filteredCollection = useMemo(() => {
    if (!AcIsSet(collection)) return null;

    const len = collection.length;
    let n = 0;
    let result = [];

    const _query = query.toLowerCase();

    for (n; n < len; n++) {
      const item = collection[n];

      const keys = Object.keys(item);

      const klen = keys.length;
      let b = 0;
      let match = false;

      for (b; b < klen; b++) {
        const key = keys[b];
        const value = item[key];

        if (AcIsSet(query) && !AcIsEmptyString(query)) {
          if (AcIsString(value)) {
            const _value = value.toLowerCase();

            if (_value.indexOf(_query) > -1) match = true;
          }
        } else {
          match = true;
        }
      }

      if (match) {
        result.push(item);
      }
    }

    return result;
  }, [collection, query, highlight]);

  const renderMultiSelectList = useMemo(() => {
    if (!AcIsSet(filteredCollection))
      return <AcEmptyBlock message={'No results available.'} />;

    if (AcIsSet(filteredCollection) && filteredCollection.length === 0)
      return <AcEmptyBlock message={'No results available.'} />;

    const len = filteredCollection.length;
    let n = 0;
    let result = [];

    for (n; n < len; n++) {
      const item = filteredCollection[n];

      const keys = Object.keys(item);
      const is_selected = selection.indexOf(item.id) > -1;
      let should_highlight = false;

      if (item.id && AcIsSet(highlight) && highlight.length > 0) {
        if (highlight.indexOf(item.id) > -1) should_highlight = true;
      }

      const klen = keys.length;
      let b = 0;
      let columns = [];
      const num = klen - (keys.indexOf('id') > -1 ? 1 : 0);

      for (b; b < klen; b++) {
        const key = keys[b];
        const value = item[key];

        if (key === 'id') continue;

        const label = AcCapitalize(key.replace(/_/g, ' '));
        let _value = AcIsSet(value) && !AcIsEmptyString(value) ? value : '-';

        if (AcIsObject(_value) && AcIsSet(_value?.type)) {
          _value = _value?.type;
        }

        if (key === 'status' && _value !== '-') {
          if (_value === 'Available') {
            _value = `<i class="ac-icon ac-icon--checkbox-marked-circle-outline"></i> <span>${_value}</span>`;
          } else if (_value === 'Reserved') {
            _value = `<i class="ac-icon ac-icon--calendar-clock"></i> <span>${_value}</span>`;
          } else if (_value === 'Rented') {
            _value = `<i class="ac-icon ac-icon--alert-circle-outline"></i> <span>${_value}</span>`;
          }
        }

        const obj = (
          <div
            className={'ac-multi-select__column'}
            key={`ac-multi-select-list-item-column-${key}-${b}-${n}`}
          >
            <div
              className={'ac-multi-select__label'}
              dangerouslySetInnerHTML={{
                __html: label,
              }}
            />
            <div
              className={'ac-multi-select__value'}
              dangerouslySetInnerHTML={{
                __html: _value,
              }}
            />
          </div>
        );

        columns.push(obj);
      }

      const object = (
        <li
          className={`ac-multi-select__item ac-multi-select__item--${num} 
            ${is_selected ? 'ac-multi-select__item--selected' : ''}`}
          key={`ac-multi-select-list-item-${item.id}`}
          onClick={() => handleSelect(item.id)}
          data-highlight={is_selected && should_highlight}
        >
          {columns}
        </li>
      );

      result.push(object);
    }

    return <ul className={'ac-multi-select__list'}>{result}</ul>;
  }, [filteredCollection, selection, highlight, query]);

  const renderSearchInput = useMemo(() => {
    return <AcSearchInput placeholder={'Find items'} callback={handleSearch} />;
  }, [instance.is_busy, handleSearch]);

  const shouldShowSearchInput = useMemo(() => {
    return (
      AcIsSet(collection) && AcIsArray(collection) && collection.length > 5
    );
  }, [collection]);

  useEffect(() => {
    if (typeof initialSelection !== 'undefined') setSelection(initialSelection);
  }, [initialSelection]);

  return (
    <>
      {introduction && (
        <AcRow className={'h-margin-bottom-25'}>
          <AcColumn>
            <AcRichContent content={introduction} />
          </AcColumn>
        </AcRow>
      )}

      {shouldShowSearchInput ||
        (alwaysShowSearch && (
          <AcRow className={'h-margin-bottom-25'}>
            <AcColumn xs={12} sm={6}>
              {renderSearchInput}
            </AcColumn>
          </AcRow>
        ))}

      {feedback && (
        <AcRow className={'h-margin-bottom-25'}>
          <AcColumn>
            <AcRichContent
              className={'ac-multi-select__feedback'}
              content={feedback}
            />
          </AcColumn>
        </AcRow>
      )}

      <AcRow>
        <AcColumn>
          <div className={'ac-multi-select'}>{renderMultiSelectList}</div>
        </AcColumn>
      </AcRow>
      {instance && instance.is_busy && <AcLoader loading={true} cover />}
    </>
  );
};

export default memo(AcMultiSelect);
